'use strict';

module.exports = {
    /**
     * Updates the MemberPromotation flag, that indicates whether or not the promotion has been triggered.
     */
    updateMemberPromotionFlag: function(memberPromotionClass) {
        if (!memberPromotionClass) {
            return;
        }
        
        // Replacing the current MemberPromotion class with the one defined in the parameters.
        var currentMemberPromotionClass = $('#maincontent').attr('class').split(' ')[1];
        if (currentMemberPromotionClass === memberPromotionClass) {
            return;
        }
        $('#maincontent').removeClass(currentMemberPromotionClass).addClass(memberPromotionClass);
        $('.suggestions-wrapper').removeClass(currentMemberPromotionClass).addClass(memberPromotionClass);
    }
};